import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "gatsby";
import aboutUsImage from "../../assets/images/nav_aboutus_1.svg";

const useStyles = makeStyles(theme => ({
  aboutLink: {
    width: "20vw",
  },
}));

const Header: React.FC<{}> = () => {
  const { aboutLink } = useStyles();
  return (
    <Box display="flex" justifyContent="flex-end" margin={2}>
      <Link to={`/ueber-uns/`} className={aboutLink}>
        <img src={aboutUsImage}></img>
      </Link>
    </Box>
  );
};

export default Header;
