import React from "react";
import SEO from "../components/seo";
import homepageLogo from "../assets/images/logo_original.svg";
import Header from "../components/homepage/header";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { graphql, Link } from "gatsby";
import "./index.css";
import Logos from "../components/common/logos";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerLogo: {
      height: "10vh",
    },
    linkMainLogo: {
      height: "65vh",
      paddingLeft: "10vw",
      paddingRight: "10vw",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        height: "55vh",
      },
    },
    mainLogo: {
      height: "100%",
    },
    pageLayout: {
      display: "flex",
      height: "100vh",
      flexDirection: "column",
      padding: "10px",
    },
    partnerLogos: {
      height: "25vh",
      [theme.breakpoints.down("sm")]: {
        height: "35vh",
      },
    },
  })
);

const IndexPage: React.FC<{ data: any }> = ({ data }) => {
  const {
    headerLogo,
    linkMainLogo,
    mainLogo,
    pageLayout,
    partnerLogos,
  } = useStyles();
  const keywords = [
    "racial profiling germany",
    "racial profiling deutsch",
    "racial profiling deutschland",
  ];
  // data.contentfullLandingPage.seoMetadata.keywords;
  const url = data.site.siteMetadata.url;

  return (
    <div className={pageLayout}>
      <span className={headerLogo}>
        <Header />
      </span>
      <SEO keywords={keywords} url={url + "racial-profiling/"} />
      <Link to={`/racial-profiling/`} className={linkMainLogo}>
        <img src={homepageLogo} className={mainLogo} />
      </Link>
      <span className={partnerLogos}>
        <Logos isIndex={true} />
      </span>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        url
      }
    }
    contentfulLandingPage {
      seoMetadata {
        keywords
      }
    }
  }
`;

export default IndexPage;
